<template>
	<div class="summary-box">
		<div class="box-title">
			<span class="title-text">总结列表</span>
			<div class="right">
				<el-date-picker
					v-model="searchTime"
					type="daterange"
					value-format="yyyyMMdd"
					format="yyyy-MM-dd"
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
					@change="searchData"
				></el-date-picker>
			</div>
		</div>
		<div class="list-box">
			<ul class="header-ul">
				<li class="select-width"><el-checkbox v-model="checkAll" @change="handleCheckAllChange"></el-checkbox></li>
				<li class="index">序号</li>
				<li class="time">爆破事件</li>
				<li class="width-s">总装药量（kg）</li>
				<li class="width-x">单段最大装药量（kg）</li>
				<li class="width-l">控制指标（cm/s）</li>
				<li class="width-xs">测点数量</li>
				<li class="width-xs">数据数量</li>
				<li class="width-xs">超限测点</li>
				<li class="width-xs">操作</li>
			</ul>
			<div class="scroll-box" v-show="item.total">
				<vue-scroll>
					<el-checkbox-group v-model="checkData" @change="handleChecked">
						<ul class="conent-ul" v-for="(i, index) in item.rows" :key="index">
							<li class="select-width"><el-checkbox :label="i.id"></el-checkbox></li>
							<li class="index">{{ index + 1 }}</li>
							<li class="time">{{ i.dtime }}</li>
							<li class="width-s">{{ i.ad }}（kg）</li>
							<li class="width-x">{{ i.md }}（kg）</li>
							<li class="width-l">{{ i.alert }}（cm/s）</li>
							<li class="width-xs">{{ i.pnum }}</li>
							<li class="width-xs">{{ i.dnum }}</li>
							<li class="width-xs">{{ i.onum }}</li>
							<li class="width-xs"><el-button type="primary" size="mini" plain @click="checkDetails(i, $event)">查看详情</el-button></li>
						</ul>
					</el-checkbox-group>
				</vue-scroll>
			</div>
			<noData v-if="!item.total" />
		</div>
		<div class="bottom-box">
			<el-button type="primary" size="small" @click="downLoadExcels">下载列表</el-button>
			<el-button type="primary" size="small" v-if="judgment('authorityList', 455, this)" @click="openReportProduction">制作报告</el-button>
			<div class="paging-box">
				<div class="sizeBox">
					<span>单页显示条数</span>
					<input type="number" v-model="changeSize" autocomplete="off" />
				</div>

				<Pagination :item="item" :current-page-change="currentPageChange"></Pagination>
			</div>
		</div>
		<!-- 详情弹窗 -->
		<el-dialog title="详情" :visible.sync="dialogDetails" custom-class="dialogDetails" :modal-append-to-body="false">
			<div class="report-box">
				<div class="info">
					<div class="cell-box">
						<div class="cell">
							<p class="title">项目名称：</p>
							<p class="content text-overflow">{{ info.name }}</p>
						</div>
						<div class="cell">
							<p class="title">爆破单位：</p>
							<p class="content text-overflow">{{ info.bcname }}</p>
						</div>
						<div class="cell">
							<p class="title">检测单位：</p>
							<p class="content text-overflow">{{ info.cname }}</p>
						</div>
						<div class="cell">
							<p class="title">爆破时间：</p>
							<p class="content text-overflow">{{ info.dtime }}</p>
						</div>
					</div>
					<div class="cell-box">
						<div class="cell">
							<p class="title">起爆坐标：</p>
							<p class="content text-overflow">{{ info.coordinates }}</p>
						</div>
						<div class="cell">
							<p class="title">位置描述：</p>
							<p class="content text-overflow">{{ info.location }}</p>
						</div>
						<div class="cell">
							<p class="title">上报时间：</p>
							<p class="content text-overflow">{{ info.ctime }}</p>
						</div>
						<div class="cell">
							<p class="title">天气：</p>
							<!-- <p class="content text-overflow">{{ info.weather }}</p> -->
							<i v-show="info.weather.indexOf('晴') != -1" class="iconfont sunny icon-qing"></i>
							<i v-show="info.weather.indexOf('阴') != -1" class="iconfont Yin icon-yintian"></i>
							<i v-show="info.weather.indexOf('雨') != -1" class="iconfont rain icon-baoyudaodabaoyu"></i>
						</div>
					</div>
					<div class="cell-box">
						<div class="cell">
							<p class="title">爆破类型：</p>
							<p class="content text-overflow">{{ typesOfExplosiveList[info.explosive].name }}</p>
						</div>
						<div class="cell">
							<p class="title">爆破方式：</p>
							<p class="content text-overflow">{{ detonationWayList[info.mode].name }}</p>
						</div>
						<div class="cell long-title">
							<p class="title">单段最大装药量（kg）</p>
							<p class="content text-overflow">{{ info.md }}</p>
						</div>
						<div class="cell long-title-s">
							<p class="title">爆破总药量（kg）</p>
							<p class="content text-overflow">{{ info.ad }}</p>
						</div>
					</div>
					<div class="cell-box">
						<div class="cell-s">
							<p class="title">炮孔（个）</p>
							<p class="content text-overflow">{{ info.hn }}</p>
						</div>
						<div class="cell-s">
							<p class="title">孔深（m）</p>
							<p class="content text-overflow">{{ info.hd }}</p>
						</div>
						<div class="cell-s">
							<p class="title">孔径（mm）</p>
							<p class="content text-overflow">{{ info.diameter }}</p>
						</div>
						<div class="cell-s">
							<p class="title">孔距（m）</p>
							<p class="content text-overflow">{{ info.distance }}</p>
						</div>
						<div class="cell-s">
							<p class="title">排距（m）</p>
							<p class="content text-overflow">{{ info.rd }}</p>
						</div>
						<div class="cell-s">
							<p class="title">填塞（m）</p>
							<p class="content text-overflow">{{ info.fi }}</p>
						</div>
					</div>
				</div>
				<div class="list"><history ref="history"></history></div>
			</div>
		</el-dialog>
		<!-- 制作报告 -->
		<el-dialog title="报告制作" :visible.sync="dialogProduction" custom-class="dialogProduction" center>
			<div class="form-box">
				<el-form ref="form">
					<el-form-item label="报告时间">
						<el-date-picker
							v-model="makeReport.productionTime"
							type="daterange"
							value-format="yyyyMMdd"
							format="yyyy-MM-dd"
							range-separator="至"
							start-placeholder="开始日期"
							end-placeholder="结束日期"
						></el-date-picker>
					</el-form-item>
					<el-form-item label="报告编号"><el-input v-model="makeReport.rpno"></el-input></el-form-item>
				</el-form>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="dialogProduction = false">取 消</el-button>
				<el-button type="primary" @click="reportProduction">确 定</el-button>
			</div>
		</el-dialog>
		<!--  简报预览  -->
		<previewChart ref="previewChart" v-if="preview" />
	</div>
</template>

<script>
// 报告弹窗测点列表页面
import history from './child/historyExp/historyExp.vue';
// 预览数据
import previewChart from '../../components/previewChart.vue';

export default {
	name: '',
	data() {
		return {
			searchTime: [], //搜索条件——时间
			checkAll: false, //数据是否全选
			checkData: [], //选中的数据
			item: {
				rows: [], //列表数据
				page: 1,
				size: 30,
				allPage: 0,
				total: 0,
				time: false,
				type: 'event',
				dataType: 'list',
				text: '暂时没有数据哦--'
			},
			changeSize: 30,
			dialogDetails: false, //详情弹窗是否显示
			//展示信息数据
			info: {
				dtime: '', // 爆破时间
				weather: '', // 天气
				location: '', // 爆破位置
				coordinates: '', // 坐标
				lng: '',
				lat: '',
				diameter: 0, // 孔径
				hn: 0, // 炮孔
				hd: 0, // 孔深
				fi: 0, // 填塞
				distance: 0, // 孔距
				rd: 0, // 排距
				md: 0, // 单段最大装药量
				ad: 0, // 爆破总药量
				explosive: 0, //  炸药类型
				mode: 0, // 起爆方式
				ctime: '', // 上报时间
				cname: '', // 检测单位
				name: '', // 项目名称
				bcname: '' // 爆破单位
			},
			// 炸药类型选择数据列表
			typesOfExplosiveList: [
				{
					value: 0,
					name: '露天深孔爆破'
				},
				{
					value: 1,
					name: '露天浅空爆破'
				},
				{
					value: 2,
					name: '地下爆破'
				},
				{
					value: 3,
					name: '拆除爆破'
				},
				{
					value: 4,
					name: '水下爆破'
				},
				{
					value: 5,
					name: '其他爆破'
				}
			],
			// 起爆方式选择数据列表
			detonationWayList: [
				{
					value: 0,
					name: '电力起爆法'
				},
				{
					value: 1,
					name: '导爆管起爆法'
				},
				{
					value: 2,
					name: '导爆索起爆法'
				},
			
				{
					value: 3,
					name: '混合起爆法'
				}
			],
			dialogProduction: false, // 报告制作弹窗是否显示
			makeReport: {
				rpno: '', //报告编号
				productionTime: [] // 报告制作时间
			}, // 制作报告参数

			parameter: {}, // 参数
			preview: false ,//是否预览波形
			authorityList: [],//权限数据
		};
	},
	created() {
		this.parameter = this.$route.query;
		this.getPermissions();
		this.getList();
	},
	mounted() {},
	computed: {},
	methods: {
		// 权限数据获取
		getPermissions(){
			this.axios.get('web2/sumary/odnr/gahr').then((res)=>{
				this.authorityList = [];
				if(res.status){
					this.authorityList = res.data;
				}
			})
		},	
		// 搜索事件
		searchData() {
			this.getList();
		},
		// 列表数据请求
		getList() {
			var data = {
				size: this.item.size,
				page: this.item.page,
				pjid: this.parameter.id
			};
			if (this.searchTime.length != 0) {
				data.begin = this.searchTime[0];
				data.end = this.searchTime[1];
			}

			this.axios.post('web2/sumary/odnr/glist', data).then(res => {
				this.item.rows = [];
				this.item.total = 0;
				this.item.allPage = 0;
				if (res.status) {
					this.item.rows = res.data.array;
					this.item.total = res.data.total;
					this.item.allPage = Math.ceil(res.data.total / this.item.size);
				}
			});
		},
		//测点列表直接全选
		handleCheckAllChange(val) {
			if (val) {
				this.item.rows.forEach(item => {
					this.checkData.push(item.id);
				});
			} else {
				this.checkData = [];
			}
		},
		//测点列表选择
		handleChecked(value) {
			let len = value.length;
			this.checkAll = len === this.item.rows.length;
		},
		// 查看详情
		checkDetails(data, e = null) {
			for (let key in this.info) {
				if (key != 'coordinates') {
					this.info[key] = data[key];
				}
			}
			this.info.coordinates = data.lng + ',' + data.lat;
			this.dialogDetails = true;
			setTimeout(() => {
				this.$refs.history.relevanceDate(data);
			}, 300);
			if (e != null) {
				this.theSelected(e);
			}
		},
		// 制作报告
		reportProduction() {
			if (this.makeReport.productionTime.length == 0 || this.makeReport.rpno == '') {
				this.$message({
					message: '请将信息填写完整！',
					type: 'warning'
				});
				return;
			}
			var data = {
				id: this.parameter.id,
				begin: this.makeReport.productionTime[0],
				end: this.makeReport.productionTime[1],
				rpno: this.makeReport.rpno
			};
			this.axios.post('web2/sumary/msr/save', data).then(res => {
				if (res.status) {
					this.$message({
						message: '报告制作成功！',
						type: 'success'
					});
					this.dialogProduction = false;
					this.makeReport.productionTime = [];
					this.makeReport.rpno = '';
				}
			});
		},
		//下载Excel表单
		downLoadExcels() {
			var data = [['序号', '爆破事件', '总装药量（kg）', '单段最大装药量（kg）', '控制指标（cm/s）', '测点数量', '数据数量', '超限测点']]; //表格标题
			var width = [8, 25, 18, 22, 20, 12, 12, 12]; //表格每列宽度
			var arr = [];
			this.item.rows.forEach((item, index) => {
				if (this.checkData.indexOf(item.id) != -1) {
					index += 1;
					var obj = {
						id: index,
						index: index.toString(),
						dtime: item.dtime,
						ad: item.ad + '(kg)',
						md: item.md + '(kg)',
						alert: item.alert + '(cm/s)',
						pnum: item.pnum,
						dnum: item.dnum,
						onum: item.onum
					};
					arr.push(obj);
				}
			});

			data.push(arr);
			// // console.log(time);

			this.summaryDownloadExcel(data, '', width);
		},
		//解析单个文件 => 预览
		filePreview(item) {
			// // console.log(item, "123123123123");
			this.axios.post(`web2/sumary/odnr/gpwd`, { id: item.id }).then(res => {
				if (res.status) {
					this.preview = true;
					this.fileBaseData = res.data;
					let fileType = this.fileBaseData.fileName.split('.')[1];
					// this.fileBaseData.deviceparam = this.$refs.fileLayer.item.deviceParamInfo;
					for (let i = 0; i < res.data.xdata.length; i++) {
						res.data.xdata[i] = Number(Number(res.data.xdata[i]).toFixed(4));
					}
					for (let i = 0; i < res.data.ydata.length; i++) {
						res.data.ydata[i] = Number(Number(res.data.ydata[i]).toFixed(4));
					}
					for (let i = 0; i < res.data.zdata.length; i++) {
						res.data.zdata[i] = Number(Number(res.data.zdata[i]).toFixed(4));
					}
					for (let i = 0; i < res.data.dataTime.length; i++) {
						if (fileType === 'bin') {
							res.data.dataTime[i] = Number(res.data.dataTime[i]);
						} else {
							res.data.dataTime[i] = Number(res.data.dataTime[i]);
						}
					}

					let arr = [res.data.xmax, res.data.ymax, res.data.zmax];
					// // console.log(fileType);
					let data = {
						sampleRate: res.data.sampleRate,
						fileType: fileType,
						date: res.data.dataTime,
						series: [res.data.xdata, res.data.ydata, res.data.zdata],
						maxData: arr.max(),
						units: res.data.paramUnit,
						maxXData: res.data.xmax,
						maxYData: res.data.ymax,
						maxZData: res.data.zmax,
						mainFX: res.data.xfrequency,
						mainFY: res.data.yfrequency,
						mainFZ: res.data.zfrequency,
						maxXTime: res.data.xmaxTime,
						maxYTime: res.data.ymaxTime,
						maxZTime: res.data.zmaxTime,
						fileName: res.data.fileName,
						fileTimeStamp: res.data.fileDate,
						pointName: res.data.pointName,
						deviceParam: res.data.deviceParam,
						companyName: res.data.companyName,
						address: res.data.address,
						deviceNum: res.data.deviceNum,
						projectName: res.data.projectName,
						negativeDelay: res.data.negativeDelay,
						// pageType: 1,//是否显示关联按钮
						fileTime: item.fileTime, //跳转关联时所需数据
						pid: item.pid //跳转关联时所需数据
					};
					//是否显示关联按钮
					if (item.hasOwnProperty('pageType')) {
						data.pageType = item.pageType;
					}
					this.fileBaseData = data;
					setTimeout(() => {
						this.$refs.previewChart.drawChart(data);
					}, 100);
				} else {
				}
			});
		},
		//翻页组件触发分页回调
		currentPageChange(type) {
			if (type === 'event') {
				if (this.changeSize !== this.item.size) {
					this.item.size = this.changeSize;
					this.item.page = 1;
				}
				this.getList();
			}
		},
		// 当前操作数据更改样式
		theSelected(e) {
			// console.log($(e.currentTarget));
			$('.summary-box .scroll-box .conent-ul').removeClass('selected-data');
			$(e.currentTarget)
				.parent()
				.parent()
				.addClass('selected-data');
		},
		// 打开报告制作弹窗
		openReportProduction() {
			this.makeReport.productionTime = [];
			this.searchTime.forEach(i => {
				this.makeReport.productionTime.push(i);
			});
			this.dialogProduction = true;
		}
	},
	components: {
		history,
		previewChart
	},
	beforeDestroy() {},
	watch: {}
};
</script>

<style lang="scss">
// 报告详情
.dialogDetails {
	position: absolute !important;
	width: 1300px !important;
	height: 700px;
	top: 165px;
	left: 0;
	right: 0;
	margin: auto !important;

	.el-dialog__title {
		font-size: 16px;
		font-weight: 600;
		color: #000;
	}

	.el-dialog__body {
		width: 100%;
		height: calc(100% - 76px);
		padding: 10px 0px 0 0px;
	}

	.dialog-footer {
		display: flex;
		justify-content: space-around;
		align-items: center;
	}

	.report-box {
		width: 100%;
		height: 100%;
		// 展示内容
		.info {
			width: calc(100% - 20px);
			padding: 0 10px;

			.sunny {
				color: #ffd54f;
				font-size: 26px;
			}

			.Yin {
				color: #909399;
				font-size: 26px;
			}

			.rain {
				color: #b3dbff;
				font-size: 26px;
			}

			.cell-box {
				width: 100%;
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-bottom: 15px;

				.cell,
				.cell-s {
					display: flex;
					justify-content: flex-start;
					align-items: center;

					.title {
						width: 73px;
						white-space: nowrap;
						font-size: 14px;
						color: #909399;
					}

					.content {
						width: calc(100% - 73px);
						font-size: 15px;
						color: #000;
					}

					.el-select {
						width: calc(100% - 73px);

						.el-input {
							width: 100%;
						}

						.el-input__icon {
							line-height: 32px;
						}
					}

					.el-input {
						width: calc(100% - 73px);

						.el-input__inner {
							height: 32px !important;
							line-height: 32px;
							padding: 0 10px;
							font-size: 15px;
							color: #000000;
						}
					}

					.el-date-editor {
						.el-input__inner {
							padding: 0 30px;
						}

						.el-input__icon {
							line-height: 32px;
						}
					}
				}

				.cell {
					width: calc(24% - 4px);
				}

				.cell-s {
					width: calc(15% - 4px);

					.title {
						width: 85px;
					}
				}

				.long-title {
					.title {
						width: 157px;
					}

					.el-input {
						width: calc(100% - 157px);
					}

					.content {
						width: calc(100% - 157px);
					}
				}

				.long-title-s {
					.title {
						width: 130px;
					}

					.el-input {
						width: calc(100% - 130px);
					}

					.content {
						width: calc(100% - 130px);
					}
				}
			}
		}
		// 测点列表
		.list {
			width: 100%;
			height: calc(100% - 130px);
		}
	}
}
// 报告制作
.dialogProduction {
	width: 450px;

	.el-dialog__body {
		padding: 30px 15px 0px 15px;

		.form-box {
			width: 100%;

			.el-input {
				width: 350px;
			}
		}

		.dialog-footer {
			display: flex;
			justify-content: flex-end;
			align-items: center;
		}
	}
}
</style>

<style scoped lang="scss">
.summary-box {
	width: 100%;
	height: 100%;

	.box-title {
		height: 60px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 20px 0 32px;

		.title-text {
			font-size: 17px;
			font-weight: 600;
			color: #303133;
			letter-spacing: 1px;
		}

		.right {
			::v-deep {
				.el-input__inner {
					height: 35px !important;
					line-height: 35px !important;
				}

				.el-date-editor .el-range__icon,
				.el-date-editor .el-range-separator,
				.el-date-editor .el-range__close-icon {
					line-height: 27px;
				}
			}
		}
	}

	.list-box {
		width: 100%;
		height: calc(100% - 112px);

		// 当前操作样式
		.selected-data {
			background-color: rgba($color: #c8c8c8, $alpha: 1) !important;
		}

		ul {
			width: calc(100% - 30px);
			padding: 0 15px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			border-top: thin solid #dcdfe6;

			li {
				text-align: center;
			}

			.align-left {
				text-align: left;
			}

			.select-width {
				width: 40px;
			}

			.time {
				width: 150px;
			}

			.index {
				width: 50px;
			}

			.width-xs {
				width: 80px;
			}

			.width-s {
				width: 122px;
			}

			.width-x {
				width: 173px;
			}

			.width-l {
				width: 141px;
			}

			.width-xl {
				width: 180px;
			}

			.width-icon {
				width: 50px;

				i {
					font-size: 20px;
				}
			}

			.icon-but {
				display: flex;
				justify-content: space-between;
				align-items: center;
			}

			.txt-but {
				display: flex;
				justify-content: space-around;
				align-items: center;

				.el-button {
					padding: 5px 8px;
				}
			}

			.align-left {
				text-align: left;
			}
		}

		.header-ul {
			height: 45px;
			background-color: #ebeef5;
			font-size: 15px;
			font-weight: 600;
			color: #4c4e51;
			letter-spacing: 1px;
		}

		.scroll-box {
			width: 100%;
			height: calc(100% - 45px);

			::v-deep {
				.el-checkbox__label {
					display: none;
				}
			}

			.conent-ul {
				height: 39px;
				line-height: 39px;
				font-size: 14px;
				color: #606266;
			}

			.conent-ul:nth-child(even) {
				background-color: #f2f2f2;
			}

			.conent-ul:nth-child(odd) {
				background-color: #ffffff;
			}

			.conent-ul:hover {
				background-color: #ebeef5;
			}

			.conent-ul:last-child {
				border-bottom: thin solid #dcdfe6;
			}
		}
	}

	.bottom-box {
		position: relative;
		width: calc(100% - 25px);
		height: 48px;
		padding-left: 25px;
		-webkit-box-flex: 0;
		-ms-flex: 0 0 48px;
		flex: 0 0 48px;
		display: flex;
		justify-content: flex-start;
		align-items: center;

		.sizeBox {
			float: left;
			line-height: 30px;
			margin: 0px 10px 0 30px;

			input {
				width: 70px;
				text-indent: 0;
				text-align: center;
				margin: 0 5px;
				line-height: 24px;
				height: 24px;
				border-radius: 2px;
				border: 1px solid #e5e5e5;
			}
		}

		.tableFooter {
			width: auto;
			display: inline-block;
			position: absolute;
			float: left;
			background: none;
			top: 0;
		}
	}
}
</style>
