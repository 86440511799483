import data from "./historyExpData.js";
// import myCascader from "../../../../../../components/myCascader";

export default {
	name: "historyExp",
	components: {
		// myCascader
	},
	props: {
		// 按钮权限
		authorityList: {
			type: Array,
			default: () => {
				return [];
			}
		}
	},
	data,
	created() {
		const D = new Date();
		let before = new Date(D.getTime()).format("yyyy/MM/dd") + " 00:00:00";
		this.form.timeS = new Date(before).getTime();
		let now = new Date(D.getTime()).format("yyyy/MM/dd") + " 23:59:59";
		this.form.timeE = new Date(now).getTime();
	},
	mounted() {},
	computed: {
		listData:{
			get: function() {
				// var data = this.item.rows.filter((item) => {
				// 	// // console.log(this.selectStation)
				// 	if (this.selectStation != 2) {
				// 		if (item.type == this.selectStation) {
				// 			return item;
				// 		}
				// 	} else {
				// 		return item;
				// 	}
				// });
				// console.log(this.item.rows)
				return this.item.rows;
			},
			//数据变化时自动调用set方法
			set: function(value) {
				// var data = value.filter((item) => {
				// 	// // console.log(this.selectStation)
				// 	if (this.selectStation != 2) {
				// 		if (item.type == this.selectStation) {
				// 			return item;
				// 		}
				// 	} else {
				// 		return item;
				// 	}
				// });
				return data;
			}
		}
	},
	methods: {
		//翻页组件触发分页回调
		currentPageChange(type) {
			if (type === "historyExp") {
				if (this.changeSize !== this.item.size) {
					this.item.size = this.changeSize;
					this.item.page = 1;
				}
				this.relevanceDate();
			}
		},
		//按钮组件点击事件回调
		buttonCallback(type) {
			if (this.checkedArr.length === 0 && type !== "search") {
				this.$messages("请选择数据", "error");
				return false;
			} else if (type === "save" || type === "preview") {
				var num = 0;
				this.checkedDevice.forEach((item) => {
					if (item == 0) {
						num += 1;
					}
				})
				if (num == this.checkedDevice.length) {
					this.$messages("请至少选择一条有效数据！", "error");
					return false;
				}
			}
			if (type === "downloadData") {
				this.downloadFile();
			} else if (type === "downloadTable") {
				this.downLoadExcels();
			} else if (type === "deleteData") {
				this.deleteFileData();
			} else if (type === "search") {
				this.item.page = 1;
				this.item.size = this.changeSize;
				this.getDeviceFile();
			} else if (type === "save") {
				var data = {
					filesId: this.checkedDevice.toString(),
					fileId: this.checkedId.id,
					pointsId: this.checkedPointId.toString(),
					pointId: this.checkedId.pid
				}
				this.$parent.buttonCallback(data, type);
			} else if (type === "preview") {
				var data = {
					filesId: this.checkedDevice.toString(),
					fileId: this.checkedId.id,
					pointsId: this.checkedPointId.toString(),
					pointId: this.checkedId.pid
				}
				this.$parent.buttonCallback(data, type);
			}
		},
		//选择搜索类型
		selectType(id, name) {
			this.form.organization = null;
			this.searchType = {
				id: id,
				name: name
			};
			this.placeholder = name;
		},
		//选中设备列表
		selectDevice(p, val) {
			this.isIndeterminate = p.length > 0 && this.listData.length > this.checkedArr.length;
			this.checkAll = this.listData.length == this.checkedArr.length;
		},
		//全选当前页
		selectAllDevice(val) {
			if (val) {
				this.checkedId = {};
				this.checkedArr = [];
				this.checkedPointId = [];
				this.checkedDevice = [];
				this.listData.forEach((item) => {
					this.checkedArr.push(item.id);
				})
			} else {
				this.checkedId = {};
				this.checkedArr = [];
				this.checkedPointId = [];
				this.checkedDevice = [];
			}
			this.isIndeterminate = false;
		},
		//批量下载源文件并打包
		downloadFile() {
			if (this.checkedArr.length == 0) {
				this.$message({
					showClose: true,
					message: '请先勾选数据！',
					type: 'warning'
				});
				return;
			}

			var data = {
				ids: this.checkedArr.toString()
			};
			this.axios.post('web2/sumary/mmd/don', data, {
				responseType: 'arraybuffer'
			}).then((res) => {
				// // console.log(res)
				var headersName = res.headers['content-disposition'];
				var fileName = '';
				// // console.log(headersName);

				if (res.headers['content-type'].split(';')[0] != 'application/json') {
					if (headersName != undefined) {
						fileName = decodeURIComponent(escape(headersName.split('=')[1]));
					}
					// data 是返回来的二进制数据
					const data = res;
					const url = window.URL.createObjectURL(new Blob([data]));
					const link = document.createElement('a')
					link.style.display = 'none'
					link.href = url;
					link.setAttribute('download', fileName)
					document.body.appendChild(link)
					link.click()
					document.body.removeChild(link)
				} else {
					// arraybuffer转json
					let resBlob = new Blob([res]);
					let reader = new FileReader();
					reader.readAsText(resBlob, "utf-8")
					reader.onload = () => {
						var type = typeof reader.result;
						if (type == 'string') {
							this.$message({
								showClose: true,
								message: reader.result,
								type: 'warning'
							});
						} else if (type == 'object') {
							let res = JSON.parse(reader.result);
							this.$message({
								showClose: true,
								message: res.message,
								type: 'warning'
							});
						}
					}
				}
			})
		},
		//下载Excel表单
		downLoadExcels() {
			var data = [
				['序号', '测点编号', '名称位置', '文件名', '幅值(X)', '主频(X)', '幅值(Y)', '主频(Y)', '幅值(Z)', '主频(Z)', '报警值', '是否超标']
			]; //表格标题
			var width = [8, 15, 24, 22, 14, 12, 14, 12, 14, 12, 8, 10]; //表格每列宽度
			var arr = [];
			this.listData.forEach((item,index) => {
				if (this.checkedArr.indexOf(item.id) != -1) {
					var flieName = item.fileName ? item.fileName : '-';
					var maxX = item.x ? (item.x + item.unit) : '-';
					var Xzp = item.xf ? (item.xf + 'Hz') : '-'
					var maxY = item.y ? (item.y + item.unit) : '-';
					var Yzp = item.yf ? (item.yf + 'Hz') : '-';
					var maxZ = item.z ? (item.z + item.unit) : '-';
					var Zzp = item.zf ? (item.zf + 'Hz') : '-';
					var obj = {
						id: index + 1,
						index: (index + 1).toString(),
						pointName: item.name ? item.name : '-',
						address: item.address ? item.address : '-',
						flieName: flieName,
						maxX: maxX,
						Xzp: Xzp,
						maxY: maxY,
						Yzp: Yzp,
						maxZ: maxZ,
						Zzp: Zzp,
						alert: item.alert ? (item.alert + item.unit) : '-',
						over: item.over,
					};
					arr.push(obj);
				}
			})

			data.push(arr);
			var time = this.dismantling(this.selectDate.fileTime);
			// // console.log(time);

			this.downloadExcel(data, time, width);
		},

		//删除设备源文件数据
		deleteFileData() {
			let filesId = '';
			if (this.checkedDevice.length == 0) {
				filesId = '0';
			} else {
				filesId = this.checkedDevice.toString();
			}
			this.$confirm('您确定要永久删除源文件吗？', '警告', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.axios
					.post(`web2/upblas/mmo/dbf`, {
						filesId: filesId
					})
					.then(res => {
						if (res.status) {
							this.$messages("删除成功！", "success");
							this.getDeviceFile(this.pointId.toString());
						} else {
							this.$messages(res.message, "error");
						}
					});
			}).catch(() => {

			});
		},
		//预览
		preview(item) {
			// if (item.zmax !== 0 && item.ymax !== 0 && item.xmax !== 0) {
			item.pageType = 2;
			this.$parent.$parent.filePreview(item);
			// }
		},
		//删除列表中的数据，假删除
		removeList(item, index) {
			this.item.rows.splice(index, 1);
		},

		//排序
		selectRankData(type) {
			if (this.item.rank !== type) {
				this.item.rank = type;
				this.item.type_ = 1;
			} else {
				if (this.item.type_) {
					this.item.type_ = 0;
				} else {
					this.item.type_ = 1;
				}
			}
			this.sortData();
		},
		//获取数据列表
		relevanceDate(item, select = {}) {
			let data = {
				id: item.id
			};
			this.checkedArr = [];
			this.checkedDevice = [];
			this.checkedPointId = [];
			this.isIndeterminate = false;
			this.checkAll = false;
			this.item.rows = [];
			this.listData = [];
			this.selectDate = select;
			// upbi/mor/grfl
			this.axios.post("web2/sumary/odnr/gdlist", data).then(res => {
				this.item.rows = [];
				if (res.status) {
					this.item.total = res.data.length;
					res.data.forEach((item,index) => {
						item.index = index;
						var val = {};
						for(let key in item){
							val[key] = item[key]
						}
						this.item.rows.push(val);
					})
					this.sortData();
				} else {
					this.item.rows = [];
					this.item.total = 0;
				}
			});
		},
		//排序
		sortData() {
			this.item.rows = this.item.rows.sort((a, b) => {
				if (this.item.rank === "number") {
					let aa = a["name"],
						bb = b["name"];
					return !this.item.type_ ? aa.localeCompare(bb) : bb.localeCompare(aa);
				} else if (this.item.rank === "x") {
					return !this.item.type_ ?
						a["x"] - b["x"] :
						b["x"] - a["x"];
				} else if (this.item.rank === "y") {
					return !this.item.type_ ?
						a["y"] - b["y"] :
						b["y"] - a["y"];
				} else if (this.item.rank === "z") {
					return !this.item.type_ ?
						a["z"] - b["z"] :
						b["z"] - a["z"];
				}
			});

			if (this.item.total != 0) {
				this.number = 0;
				this.checkAll = true;
				this.selectAllDevice(true);
			}
		},

		//选择筛选类型
		selectData() {
			this.checkedArr = [];
			this.checkedDevice = [];
			this.checkedPointId = [];
			this.isIndeterminate = false;
			this.checkAll = false;
			this.sortData();
		},

		//获取选择的数据并把和选中数据同名的测点标记
		getClazz(item) {
			// // console.log(item);
			// // console.log(this.selectDate)
			if (item.hasOwnProperty('tag')) {
				if (item.tag == 1) {
					return "tableList selecteds";
				} else {
					return "tableList";
				}
			} else {
				return "tableList";
			}
		},

	},
	beforeDestroy() {},
	watch: {}
};
