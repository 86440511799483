<template>
	<div class="historyExpPage pageContainer">
		<div class="pageMenu">
			<ul class="nav no-select-text">
				<li class="selected">关联数据</li>
			</ul>
		
			<!-- <div class="screen">
				<span>数据：</span>
				<el-select v-model="selectStation" @change="selectData" placeholder="请选择筛选条件">
					<el-option v-for="item in deviceType" :key="item.id" :label="item.name" :value="item.id"></el-option>
				</el-select>
			</div> -->
		</div>
		<div class="tableHeader no-select-text">
			<span style="width: 5%;"><el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="selectAllDevice">全选</el-checkbox></span>
			<span style="width: 4%;text-align: left;">序号</span>
			<span style="width: 17%;text-align: center;">文件名</span>
			<span style="width: 11%;" @click="selectRankData('number')" :class="item.rank === 'number' ? 'pointer select' : 'pointer'">
				测点名
				<i :class="item.type_ ? 'el-icon-bottom' : 'el-icon-top'"></i>
			</span>
			<span style="width: 16%;">
				<em @click="selectRankData('x')" :class="item.rank === 'x' ? 'pointer select' : 'pointer'">
					幅值（X）
					<i :class="item.type_ ? 'el-icon-bottom' : 'el-icon-top'"></i>
				</em>
				<em>主频（X）</em>
			</span>
			<span style="width: 16%;">
				<em @click="selectRankData('y')" :class="item.rank === 'y' ? 'pointer select' : 'pointer'">
					幅值（Y）
					<i :class="item.type_ ? 'el-icon-bottom' : 'el-icon-top'"></i>
				</em>
				<em>主频（Y）</em>
			</span>
			<span style="width: 16%;">
				<em @click="selectRankData('z')" :class="item.rank === 'z' ? 'pointer select' : 'pointer'">
					幅值（Z）
					<i :class="item.type_ ? 'el-icon-bottom' : 'el-icon-top'"></i>
				</em>
				<em>主频（Z）</em>
			</span>
			<span style="width: 7%;text-align: left;">报警值</span>
			<span style="width: 3%;">预警</span>
			<span style="width: 5%;text-align: center;text-indent: 0;">编辑</span>
		</div>

		<div class="tableContainer">
			<div class="tableContent">
				<noData v-if="!item.total" />
				<el-scrollbar :native="false" :noresize="false" tag="div" v-if="item.total">
					<ul :class="getClazz(p)" v-for="(p, i) in listData" :key="i">
						<el-checkbox-group v-model="checkedArr" @change="selectDevice($event, p)">
							<li class="no-select-text" style="width: 5%;"><el-checkbox :label="p.id"></el-checkbox></li>
							<li style="width: 4%;">{{ i+1 }}</li>
							<li style="width: 17%;">
								<span :title="p.fileName ? p.fileName : '-'">{{ p.fileName ? p.fileName : '-'}}</span>
							</li>
							<li style="width: 11%;">
								<span :title="p.name">{{ p.name}}</span>
							</li>
							<li style="width: 16%;">
								<em v-show="p.fileName == ''">-</em>
								<em v-show="p.fileName == ''">-</em>
								<em class="em1" v-show="p.fileName != ''" :title="p.x !== '-' ? p.x + p.unit : '-'">
									{{ p.x !== '-' ? p.x : '-' }}{{ p.x !== '-' ? p.unit : '' }}
								</em>
								<em class="em2" v-show="p.fileName != ''" :title="p.xf !== '-' ? p.xf + 'Hz' : '-'">{{ p.xf !== '-' ? p.xf + 'Hz' : '-' }}</em>
							</li>
							<li style="width: 16%;">
								<em v-show="p.fileName == ''">-</em>
								<em v-show="p.fileName == ''">-</em>
								<em class="em1" v-show="p.fileName != ''" :title="p.y !== '-' ? p.y + p.unit : '-'">
									{{ p.y !== '-' ? p.y : '-' }}{{ p.y !== '-' ? p.unit : '' }}
								</em>
								<em class="em2" v-show="p.fileName != ''" :title="p.yf !== '-' ? p.yf + 'Hz' : '-'">{{ p.yf !== '-' ? p.yf + 'Hz' : '-' }}</em>
							</li>
							<li style="width: 16%;">
								<em v-show="p.fileName == ''">-</em>
								<em v-show="p.fileName == ''">-</em>
								<em class="em1" v-show="p.fileName != ''" :title="p.z !== '-' ? p.z + p.unit : '-'">
									{{ p.z !== '-' ? p.z : '-' }}{{ p.z !== '-' ? p.unit : '' }}
								</em>
								<em class="em2" v-show="p.fileName != ''" :title="p.zf !== '-' ? p.zf + 'Hz' : '-'">{{ p.zf !== '-' ? p.zf + 'Hz' : '-' }}</em>
							</li>
							<li style="width: 7%;">
								<span v-show="p.fileName == ''">-</span>
								<span v-show="p.fileName != ''" :title="p.alert !== '-' ? p.alert : '-'">{{ p.alert !== '-' ? p.alert : '-' }}{{ p.alert !== '-' ? p.unit : '' }}</span>
							</li>
							<li style="width: 3%; text-align: center;" :class="p.over === '-' ? '' : p.over == '是' ? 'red' : 'blue'">
								<span v-show="p.fileName == ''">-</span>
								<span v-show="p.fileName != ''">{{ p.over }}</span>
							</li>
							<li style="width: 5%;" class="compile">
								<i :style="{ opacity: p.fileName != '' ? 1 : 0 }" @click="preview(p)" title="预览数据" class="icon iconfont icon-yulan outline blue"></i>
								<!-- <i @click="removeList(p,i)" title="移出列表" class="el-icon-delete delete red"></i> -->
							</li>
						</el-checkbox-group>
					</ul>
				</el-scrollbar>
			</div>

			<div class="tableFooter">
				<Button class="downloadTable" type="downloadTable" :button-callback="buttonCallback" text="下载表单" />
				<Button class="downloadData" type="downloadData" :button-callback="buttonCallback" text="下载数据" />
				<!-- <Button class="createWord" type="save" :button-callback="buttonCallback" text="生成报告" /> -->
				<!-- :disabled="!judgment('authorityList',6,this)" -->
				<!-- <Button class="createWord" type="preview" :button-callback="buttonCallback" text="预览报告" />
				<Button class="deleteData" type="deleteData" :button-callback="buttonCallback" text="删除数据" /> -->
			</div>
		</div>
	</div>
</template>

<script>
import historyExp from './historyExp.js';

export default historyExp;
</script>

<style scoped lang="scss">
@import './historyExp.scss';
</style>
